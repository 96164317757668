import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ProfessionalServicesService } from '../../professional-services.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { debounceTime } from 'rxjs/operators';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { Location } from '@angular/common';
import { ServiceProjectDataSource } from '../../models/service-project.datasource';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { RoleService } from '../../../shared/services/role.service';
import { CloudMigrationToolService } from 'src/app/cloud-migration-tool/cloud-migration-tool.service';
import { TagsService } from '../../../tags/tags.service';
import { Tag } from '../../../shared/components/tags-popup/tags-datasource';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Component({
  selector: 'app-professional-services-project-list',
  templateUrl: './professional-services-project-list.component.html',
  styleUrls: ['./professional-services-project-list.component.scss']
})
export class ProfessionalServicesProjectListComponent implements OnInit {

  // TODO: Rework code to be clean and page load to not show results until data is filtered.
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static:true }) table: MatTable<any>;
  dataSource: ServiceProjectDataSource;
  @Input() isCmtProj: boolean;

  projects: any;
  displayedColumns: Array<string> = [
    'clientName',
    'projectName',
    'oppurtunityNumber',
    'createdBy',
    'tags',
    'createdDate',
    'serviceProjectStatus',
    'actions'
  ];;
  pageSize: number = 100;
  isNameAscending: boolean;
  isClientNameAscending: boolean;
  isDateAscending: boolean;
  isOppQAscending: boolean;
  filterForm: UntypedFormGroup;
  allProjects: Array<any>;
  filteredProjects: Array<any>;
  user: AccountInfo | null;
  isAdmin: boolean;
  isArchitectEngineer: boolean;
  isAvayaArchitectEngineer: boolean;
  isProjectManager: boolean;
  isProjectManagerOfficer: boolean;
  isServicesOperations: boolean;
  isSalesSupport: boolean;
  isDirector: boolean;
  isSales: boolean;
  pagination: any;
  isCreatedByFirstNameDesc: boolean;
  employees: any[];
  sessionInfo: any;
  sessionStorageKey: string;
  tags: Array<Tag>;
  constructor(private projectService: ProfessionalServicesService, private dialog: MatDialog, private fb: UntypedFormBuilder,
    private cloudMigrationToolService: CloudMigrationToolService,
    private authService: MsalService, private adminService: AdminService, private location: Location, private route: ActivatedRoute,
    private roleService: RoleService,
    private tagsService: TagsService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.setupPermissions();
    this.setupDefaultSorts();
    this.getEmployees();
    this.getTags();
    this.filterForm = this.fb.group({
      oppQ: [''],
      clientName: [''],
      projectName: [''],
      serviceProjectStatus: [0],
      resourceAssigned: [],
      employee: [],
      tag: ['']
    });
    this.sessionStorageKey = this.getStorageKey();
    let storageData = this.sessionStorageService.fetchStorageData(this.sessionStorageKey);
    if (storageData) {
      this.sessionInfo = this.sessionStorageService.parseStorageData(storageData);            
    } else {
      this.sessionInfo = this.filterForm.value; 
      this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
    }
    this.getTableData();
  }

  getStorageKey() {
     return !this.isCmtProj ? "filteredProjectsData" : "filteredCMTProjectsData"
  }
  setupDefaultSorts() {
    this.isCreatedByFirstNameDesc = false;
    this.isNameAscending = false;
    this.isClientNameAscending = false;
    this.isOppQAscending = false;
    this.isDateAscending = false;
  }
  setupPermissions() {
    this.user = this.authService.instance.getActiveAccount();
    this.isProjectManager = false;
    this.isDirector = false;
    this.isAdmin = false;
    this.isProjectManager = false;
    this.isDirector = this.hasRole('PortalSEDirector') || this.hasRole('projects.approver');
    this.isSales = this.hasRole('PortalSEPostsalesEngineer');
    this.isSalesSupport = this.hasRole('PortalSalesSupport');
    this.isAdmin = this.hasRole('PortalAdmin');
    this.isArchitectEngineer = this.hasRole('PortalSEPresalesArchitect');
    this.isProjectManagerOfficer = false;
    if (this.hasRole('PortalSEProjectManager')) {
      this.isProjectManager = true;
    } if (this.hasRole('PortalSEProjectManagerOfficer')) {
      this.isProjectManager = true;
      this.isProjectManagerOfficer = true;
    }
    if (this.hasRole('PortalSEServiceOperations') || this.hasRole('ProfessionalServiceCoordinator')) {
      this.isServicesOperations = true;
    }
  }
  getEmployees() {
    this.adminService.getAllEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      });
  }
  getTags() {
    this.tagsService.getTags()
      .subscribe(res => {
        this.tags = res;
      });
  }
  filterByEmployee(projects: any[], employeeId: string) {
    if (employeeId) {
      const filteredData = projects.filter(temp => {

        if (temp.createdBy) {
          return temp.createdBy.employeeId == employeeId;
        } else {
          return false;
        }
      });
      return filteredData;
    } else {
      return this.projects;
    }
  }

cloneProject(projectId: string) {
  this.projectService.cloneProject(projectId)
    .subscribe(
      clonedProject => {
        this.getTableData();
      },
      error => {
        console.error('Error cloning project:', error);
      }
    );
}

  filterToMonitor() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        formValue => {
          if (!this.filteredProjects) {
            this.filteredProjects = [];
          }

          Object.assign(this.filteredProjects, this.projects);
          const oppQValue: string = formValue['oppQ'];
          const clientNameValue: string = formValue['clientName'];
          const projectNameValue: string = formValue['projectName'];
          const serviceProjectStatusValue: number | null = formValue['serviceProjectStatus'];
          const resourceAssigned: number = formValue['resourceAssigned'];
          const selectedEmployee: string = formValue['employee'];
          const tagValue: string = formValue['tag'];
          if (clientNameValue.length < 1 && projectNameValue.length < 1 && oppQValue.length < 1) {
            Object.assign(this.filteredProjects, this.projects);
          }
          if (serviceProjectStatusValue || serviceProjectStatusValue === 0) {
            this.filteredProjects = this.filteredProjects.filter(sp => sp.serviceProjectStatus === serviceProjectStatusValue);
          }
          else {
            //this.filteredProjects = this.filteredProjects.filter(sp => sp.serviceProjectStatus === serviceProjectStatusValue);
          }
          if (projectNameValue) {
            this.filteredProjects = this.filteredProjects.filter(bp => {
              return bp.projectName.toLocaleLowerCase().indexOf(projectNameValue.toLocaleLowerCase()) > -1;
            });
          }
          if (clientNameValue.length > 0) {
            this.filteredProjects = this.filteredProjects.filter(bp => {
              return bp.client.commonName.toLocaleLowerCase().indexOf(clientNameValue.toLocaleLowerCase()) > -1;
            });
          }
          if (oppQValue.length > 0) {
            this.filteredProjects = this.filteredProjects.filter(bp => {
              return bp.oppurtunityNumber.toLocaleLowerCase().indexOf(oppQValue.toLocaleLowerCase()) > -1;
            });
          }
          if (tagValue) {
            this.filteredProjects = this.filteredProjects.filter(sp => {
              if (sp.serviceProjectTags) {
                let doesExist = false;
                if (sp.serviceProjectTags.length > 0) {
                  sp.serviceProjectTags.forEach((tag: any) => {
                    if (!doesExist) {
                      doesExist = tag.tagId == tagValue;
                    }
                  });
                }
                return doesExist;
              } else {
                return false;
              }
            });
          }
          if (selectedEmployee) {
            this.filteredProjects = this.filterByEmployee(this.filteredProjects, selectedEmployee);
          }
          if (resourceAssigned == undefined || resourceAssigned == null) {

          }
          else if (resourceAssigned === 0) {
            this.filteredProjects = this.filteredProjects.filter(sp => sp.isAllResourcesAssigned === true);
          } else if (resourceAssigned === 1) {
            this.filteredProjects = this.filteredProjects.filter(sp => sp.isAllResourcesAssigned === false);
          } else {
            this.filteredProjects = this.filteredProjects;
          }
          this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
          this.setTableData(this.filteredProjects);
        }
      );
  }
  getServiceProjectStatus(serviceProjectStatusValue: any, isValue?: boolean) {
    switch (serviceProjectStatusValue) {
      case 0:
      case 'Active': return isValue ? 'Active' : 0;
      case 1:
      case 'Inactive': return isValue ? 'Inactive' : 1;
      case 2:
      case 'Lost': return isValue ? 'Lost' : 2;
      case 3:
      case 'Complete': return isValue ? 'Complete' : 3;
      default: return '';
    }
  }
  getTableData(sliceAmount?: number) {
    if (this.isAdmin || this.isDirector || this.isServicesOperations || this.isSalesSupport || this.isSales || this.isProjectManagerOfficer) {
      if (this.isCmtProj) {
        this.cloudMigrationToolService.getProjects()
          .subscribe(res => {
            this.projects = res;

            this.setTableData(res);
            this.filterToMonitor();
            this.updateForm();
          });
      }
      else {
        this.projectService.getProjects()
          .subscribe(res => {
            this.projects = res;

            this.setTableData(res);
            this.filterToMonitor();
            this.updateForm();
            // if (sliceAmount) {

            //   const data = this.projects.slice(0, sliceAmount);
            // } else {
            //   this.setTableData();
            // }

          });
      }
    } else {
      if (this.isCmtProj) {
        this.cloudMigrationToolService.getProjects()
          .subscribe(res => {
            this.projects = res;
            this.setTableData(res);
            this.filterToMonitor();
            this.updateForm();

          });
      }
      else {
        this.projectService.getAssignedProjects()
          .subscribe(res => {
            this.projects = res;
            this.setTableData(res);
            this.filterToMonitor();
            this.updateForm();
          });
      }
    }
  }
  updateForm() {
    this.filterForm.patchValue({
      oppQ: this.sessionInfo['oppQ'] ? this.sessionInfo['oppQ'] : '',
      clientName: this.sessionInfo['clientName'] ? this.sessionInfo['clientName'] : '',
      projectName: this.sessionInfo['projectName'] ? this.sessionInfo['projectName'] : '',
      serviceProjectStatus: this.sessionInfo['serviceProjectStatus'] == null || this.sessionInfo['serviceProjectStatus'].toString() == "" ? '' : this.sessionInfo['serviceProjectStatus'],
      resourceAssigned: this.sessionInfo['resourceAssigned'] == null || this.sessionInfo['resourceAssigned'].toString() == "" ? '' : this.sessionInfo['resourceAssigned'],
      employee: this.sessionInfo['employee'] ? this.sessionInfo['employee'] : '',
      tag: this.sessionInfo['tag'] ? this.sessionInfo['tag'] : '',
    });
    //this.selectedEmployeeId ? this.filterByEmployee() : '';
  }

  setTableData(data: Array<any>) {
    if (data) {
      // data = data.filter(p => p.serviceProjectStatus == this.filterForm.controls['serviceProjectStatus'].value);
      // data = data.slice(0, this.pageSize);

      this.dataSource = new ServiceProjectDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    } else {
      // data = this.projects.filter(p => p.serviceProjectStatus == this.filterForm.controls['serviceProjectStatus'].value);
      this.dataSource = new ServiceProjectDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    }
  }
  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectService.deleteProject(id)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });
  }

  resetFilters() {
    this.filterForm.controls['oppQ'].setValue('');
    this.filterForm.controls['clientName'].setValue('');
    this.filterForm.controls['projectName'].setValue('');
    this.filterForm.controls['serviceProjectStatus'].setValue(0);
    this.filterForm.controls['resourceAssigned'].setValue('');
    this.filterForm.controls['employee'].setValue('');
    this.filterForm.controls['tag'].setValue('');
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }
}
