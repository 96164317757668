<div class="col-md-12" [class.loading]="!employees">
  <mat-card>
    <mat-card-content>
      <div class="col-md-12">
        <fieldset>
          <legend>
            Search Selection
          </legend>
          <form [formGroup]="filterForm">
            <mat-form-field>
              <input matInput type="text" placeholder="Filter by Client Name" formControlName="clientName" />
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" placeholder="Filter by Project Name" formControlName="projectName" />
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" placeholder="Filter by BF Number" formControlName="oppQ" />
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Filter by Status" formControlName="serviceProjectStatus" disableOptionCentering>
                <mat-option [value]="''">All</mat-option>
                <mat-option [value]="0">Active</mat-option>
                <mat-option [value]="1">Inactive</mat-option>
                <mat-option [value]="2">Lost</mat-option>
                <mat-option [value]="3">Complete</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="normalSize">
              <mat-select formControlName="employee" disableOptionCentering #employee placeholder="Employee Filter">
                <mat-option [value]="''">
                  None
                </mat-option>
                <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                  {{emp.firstName + " " + emp.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Filter By Tag" formControlName="tag" disableOptionCentering>
                <mat-option [value]="''">None</mat-option>
                <mat-option *ngFor="let tag of tags" [value]="tag.id">
                  {{tag.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Resources Assigned Status</mat-label>
              <mat-select formControlName="resourceAssigned" disableOptionCentering>
                <mat-option [value]="''">None</mat-option>
                <mat-option [value]="0">Assigned</mat-option>
                <mat-option [value]="1">Not Assigned</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
              <mat-icon>clear</mat-icon>
            </button>
          </form>
        </fieldset>
      </div>
      <br />
      <div class="col-md-12">
        <mat-table matSort #table [dataSource]="dataSource" class="apply-border">
          <ng-container matColumnDef="projectName">
            <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header>
              Project Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.projectName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="clientName">
            <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header>
              Client Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.client?.commonName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="oppurtunityNumber">
            <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header>
              BF Number
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.oppurtunityNumber}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.serviceProjectTags">
                <span *ngFor="let tag of tags | filterByTagId: element.serviceProjectTags; first as isFirst">
                  <span *ngIf="!isFirst">, </span><span> {{tag.name}}</span>
                </span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="serviceProjectStatus">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.serviceProjectStatus == 0">Active</span>
              <span *ngIf="element.serviceProjectStatus == 1">Inactive</span>
              <span *ngIf="element.serviceProjectStatus == 2">Lost</span>
              <span *ngIf="element.serviceProjectStatus == 3">Complete</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header>
              Created By
              <!-- <mat-icon class="twelve">
          (click)="sortCreatedByName()"
          sort
      </mat-icon> -->
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.createdBy?.firstName}}
              {{element.createdBy?.lastName}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdDate">
            <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header>
              Created
              Date
              <!-- <mat-icon
          (click)="sortByDate()"
          class="twelve">
          sort
      </mat-icon> -->
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.createdDate | date: 'M/d/yyyy'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="!isCmtProj">
                <button id = "kebabMenu" [attr.data-id]="element.id" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item color="default" type="button" [routerLink]="[element.id,'edit']"
                    [disabled]="isSales || isSalesSupport">
                    <mat-icon>create</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item color="accent" type="button" [routerLink]="[element.id]"
                    [disabled]="isSalesSupport">
                    <mat-icon>search</mat-icon>
                    <span>Service Estimates(SE)</span>
                  </button>
                  <button mat-menu-item color="accent" type="button" (click)="cloneProject(element.id)">
                    <mat-icon>library_add</mat-icon>
                    <span>Clone Project</span>
                  </button>
                  <button mat-menu-item *ngIf="isAdmin" (click)="deleteRecord(element.id)" color="warn" type="button">
                    <mat-icon>remove</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </ng-container>
              <ng-container *ngIf="isCmtProj">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="[element.id,'view']">
                    <mat-icon>search</mat-icon>
                    <span>View</span>
                  </button>
                  <button mat-menu-item (click)="deleteRecord(element.id)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>

              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="!(dataSource && dataSource.data.length > 0)" class="no-records">
          No records found
        </div>
      </div>
      <div>
        <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
          [pageSizeOptions]="[5, 25, 50, 100]">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
