import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProfessionalServicesService } from '../../professional-services.service';

@Component({
  selector: 'export-quote-popup',
  templateUrl: './export-quote-popup.component.html',
  styleUrls: ['./export-quote-popup.component.scss']
})

export class ExportQuotePopupComponent implements OnInit {
  selectedServiceEstimates: any[] = [];
  selectedChangeOrders: any[] = [];

  filteredServiceEstimates: any[] = [];
  filteredChangeOrders: any[] = [];
  @ViewChild('downloadCsvLink', { static: true }) downloadCsvLink: ElementRef;
  
  constructor(
    public dialogRef: MatDialogRef<ExportQuotePopupComponent>,
    private serviceEstimateService: ProfessionalServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    const today = new Date();

    // Filter service estimates based on isApproved and expirationDate
    this.filteredServiceEstimates = this.data.serviceEstimates.filter((estimate: { isApproved: any; expirationDate: string | number | Date; serviceEstimateStatus: any; serviceEstimateComponents?: { isMultiOverride: boolean }[]; }) => 
      estimate.isApproved && new Date(estimate.expirationDate) >= today && estimate.serviceEstimateStatus != '4' && (!estimate.serviceEstimateComponents || estimate.serviceEstimateComponents.every(component => !component.isMultiOverride))
    );      

    // Filter change orders based on isApproved and expirationDate
    this.filteredChangeOrders = this.data.changeOrders.filter((order: { isApproved: any; expirationDate: string | number | Date; serviceEstimateStatus: any; serviceEstimateComponents?: { isMultiOverride: boolean }[]; }) => 
      order.isApproved && new Date(order.expirationDate) >= today && order.serviceEstimateStatus != '4' && (!order.serviceEstimateComponents || order.serviceEstimateComponents.every(component => !component.isMultiOverride))
    );

    console.log('Filtered Service Estimates:', this.filteredServiceEstimates);
    console.log('Filtered Change Orders:', this.filteredChangeOrders);
  }

  exportToCSV(): void {
    // Extract the IDs from selectedServiceEstimates and selectedChangeOrders
    const serviceEstimateIds = [
        ...this.selectedServiceEstimates.map(se => se.serviceEstimateId),
        ...this.selectedChangeOrders.map(co => co.serviceEstimateId)
    ];
    // Call the service to run the CSV export
    this.serviceEstimateService.runCsvExport(serviceEstimateIds).subscribe(response => {

      const updatedResponse = response.map((item: any) => {
        return {
            ...item,
            'Inv. Seq': '',
            'Inv. Desc': '',
            'Inv. Qty.': ''
        };
      });

        const customHeaders = [
          'Model/Part #',
          'Description',
          'Qty.',
          'Suggested List Price',
          'Unit Price',
          'MIT Discount',
          'Inv. Seq',  
          'Inv. Desc', 
          'Inv. Qty.'
        ];

        const fileText = this.serviceEstimateService.convertToCsv(JSON.stringify(updatedResponse), customHeaders);
        const blob = new Blob([fileText], {
          type: 'text/csv'
        });
        const url = window.URL.createObjectURL(blob);
        const link = this.downloadCsvLink.nativeElement;
        link.href = url;
        link.download = 'exportToCWSQuote.csv';
        link.click();
        window.URL.revokeObjectURL(url);

    }, error => {
        // Handle errors if necessary
        console.error('CSV export failed:', error);
    });
  }
}

